import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import 'core-js/stable'

import _ from 'lodash'
import axios from "axios";
import Vuetify from 'vuetify'
import CoreuiVue from '@coreui/vue'
import VueAxios from "vue-axios";
import babelPolyfill from 'babel-polyfill'
import VueClipboard from 'vue-clipboard2'
import VueGeolocation from 'vue-browser-geolocation'
// import PincodeInput from 'vue-pincode-input'
import VueToast from 'vue-toast-notification'

import 'vue-toast-notification/dist/theme-sugar.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { iconsSet as icons } from './assets/icons/icons.js'

import OtpInput from "@bachdgvn/vue-otp-input"
import CodeInput from "vue-verification-code-input"

Vue.use(_)
Vue.use(VueToast)
Vue.use(VueGeolocation)
Vue.use(VueClipboard)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuetify)
Vue.use(CodeInput)

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

Vue.component("v-otp-input", OtpInput)
Vue.use(VueAxios, axios)

new Vue({
    el: '#app',
    router,
    store,
    icons,
    template: '<App/>',
    components: {
        App
    }
})