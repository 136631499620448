import Vue from 'vue'
import Router from 'vue-router'

//Token Check
import Auth from '@/services/Auth'

// Views - Pages
const Page404 = () =>
    import ('@/views/pages/Page404')
const Page500 = () =>
    import ('@/views/pages/Page500')
const Login = () =>
    import ('@/views/pages/Login')
const Register = () =>
    import ('@/views/pages/Register')

// Students
const CheckIn = () =>
    import ('@/views/students/CheckIn')

const Profiles = () =>
    import ('@/views/students/Profile')
    // Auth
const Callback = () =>
    import ('@/views/auth/Callback')
const Signout = () =>
    import ('@/views/auth/SignOut')
const Signin = () =>
    import ('@/views/auth/SignIn')

Vue.use(Router)

// export default new Router({
const router = new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes()
})

function configRoutes() {
    return [{
            path: '/',
            redirect: '/student/profile',
            name: 'Home',
        },
        {
            path: '/pages',
            redirect: '/pages/404',
            name: 'Pages',
            component: {
                render(c) { return c('router-view') }
            },
            children: [{
                    path: '404',
                    name: 'Page404',
                    component: Page404
                },
                {
                    path: '500',
                    name: 'Page500',
                    component: Page500
                },
                {
                    path: 'login',
                    name: 'Login',
                    component: Login
                },
                {
                    path: 'register',
                    name: 'Register',
                    component: Register
                }
            ]
        },
        // {
        //     path: '/student',
        //     redirect: '/student/code',
        //     name: 'student',
        //     component: {
        //         render(c) { return c('router-view') }
        //     },
        //     children: [{
        //         path: 'profile',
        //         name: 'Default-profiles',
        //         component: Profiles
        //     }, {
        //         path: 'code',
        //         redirect: '/student/code',
        //         name: 'Code',
        //         component: {
        //             render(c) { return c('router-view') }
        //         },
        //         children: [{
        //             path: '/',
        //             name: 'Profiles',
        //             component: Profiles
        //         }, {
        //             path: ':eventid',
        //             name: 'Checkin',
        //             component: CheckIn
        //         }]
        //     }]
        // },
        {
            path: '/student',
            redirect: '/student/profile',
            name: 'student',
            component: {
                render(c) { return c('router-view') }
            },
            children: [{
                path: 'profile',
                name: 'Default-profiles',
                component: Profiles
            }, {
                path: 'code',
                name: 'Code',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [{
                    path: '/',
                    redirect: '/student/profile',
                }, {
                    path: ':eventid',
                    name: 'Checkin',
                    component: CheckIn
                }]
            }]
        },
        {
            path: '/callback',
            component: {
                render(c) { return c('router-view') }
            },
            children: [{
                path: '/',
                name: 'Callback',
                component: Callback
            }, ]
        },
        {
            path: '/signout',
            component: {
                render(c) { return c('router-view') }
            },
            children: [{
                path: '/',
                name: 'Signout',
                component: Signout
            }, ]
        },
        {
            path: '/signin',
            component: {
                render(c) { return c('router-view') }
            },
            children: [{
                path: '/',
                name: 'Signin',
                component: Signin
            }, ]
        },
        {
            path: '*',
            component: {
                render(c) { return c('router-view') }
            },
            children: [{
                path: '/',
                name: '404',
                component: Page404
            }, ]
        }
    ]
}

router.beforeEach((to, from, next) => {
    let Acctype = localStorage.getItem('AccType')
    let isAdmin = localStorage.getItem('Admin')
    let token = localStorage.getItem('access_token')
    console.log("token:", (typeof token !== 'undefined'))
    if (isAdmin === 'admin') {
        console.log("Admin:", isAdmin)
    }
    console.log("Acctype:", Acctype)
    console.log("to.name:", to.name)
    console.log("to", to)

    if (to.name === 'Checkin') {
        if (to.params.eventid) {
            localStorage.removeItem('stdEventId')
            localStorage.setItem('stdEventId', to.params.eventid)
        }
    }

    if (token !== null) {
        Auth.getUserInGraph(token).then(user => {
            console.log("🚀 ~ file: index.js ~ line 510 ~ Auth.getUserInGraph ~ user", user)
            if (user instanceof Error) {
                console.log("Token Expired")
                localStorage.removeItem('access_token')
                localStorage.removeItem('email')
                localStorage.removeItem('name')
                localStorage.removeItem('AccType')
                localStorage.removeItem('code')
                localStorage.removeItem('userProfile')
                localStorage.removeItem('Admin')
                next({ name: 'Signin' })
            } else {
                console.log("Token Not Expire")
            }
        })
    }
    if (to.name !== 'Signin' && to.name !== 'Callback' && to.name !== 'Page500' && token === null) {
        next({ name: 'Signin' })
    } else if (to.name === 'Dashboard' || to.name === 'EventList' || to.name === 'EventDetail') {
        next({ name: 'student' })
    } else {
        next()
    }
})

export default router